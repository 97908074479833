(function (factory) {
    typeof define === 'function' && define.amd ? define(['kendo.core'], factory) :
    factory();
})((function () {
    (function( window, undefined$1 ) {
        kendo.cultures["vi-VN"] = {
            name: "vi-VN",
            numberFormat: {
                pattern: ["-n"],
                decimals: 2,
                ",": ".",
                ".": ",",
                groupSize: [3],
                percent: {
                    pattern: ["-n%","n%"],
                    decimals: 2,
                    ",": ".",
                    ".": ",",
                    groupSize: [3],
                    symbol: "%"
                },
                currency: {
                    name: "Vietnamese Dong",
                    abbr: "VND",
                    pattern: ["-n $","n $"],
                    decimals: 2,
                    ",": ".",
                    ".": ",",
                    groupSize: [3],
                    symbol: "₫"
                }
            },
            calendars: {
                standard: {
                    days: {
                        names: ["Chủ Nhật","Thứ Hai","Thứ Ba","Thứ Tư","Thứ Năm","Thứ Sáu","Thứ Bảy"],
                        namesAbbr: ["CN","T2","T3","T4","T5","T6","T7"],
                        namesShort: ["C","H","B","T","N","S","B"]
                    },
                    months: {
                        names: ["Tháng Giêng","Tháng Hai","Tháng Ba","Tháng Tư","Tháng Năm","Tháng Sáu","Tháng Bảy","Tháng Tám","Tháng Chín","Tháng Mười","Tháng Mười Một","Tháng Mười Hai"],
                        namesAbbr: ["Thg1","Thg2","Thg3","Thg4","Thg5","Thg6","Thg7","Thg8","Thg9","Thg10","Thg11","Thg12"]
                    },
                    AM: ["SA","sa","SA"],
                    PM: ["CH","ch","CH"],
                    patterns: {
                        d: "dd/MM/yyyy",
                        D: "dd MMMM yyyy",
                        F: "dd MMMM yyyy h:mm:ss tt",
                        g: "dd/MM/yyyy h:mm tt",
                        G: "dd/MM/yyyy h:mm:ss tt",
                        m: "dd MMMM",
                        M: "dd MMMM",
                        s: "yyyy'-'MM'-'dd'T'HH':'mm':'ss",
                        t: "h:mm tt",
                        T: "h:mm:ss tt",
                        u: "yyyy'-'MM'-'dd HH':'mm':'ss'Z'",
                        y: "MMMM yyyy",
                        Y: "MMMM yyyy"
                    },
                    "/": "/",
                    ":": ":",
                    firstDay: 1
                }
            }
        };
    })();

}));
